@import "mixins";
@import "../mixins";
@import "../variables";

.product-description-container {
    margin: 3rem -15px;

    .product-banner {
        .image {
            text-align: center;
        }

        .text {
            padding: 30px;
        }

        img {
            max-height: 400px;
            object-fit: contain;
        }

        p, h1, h2, h3, h4, h5, h6 {
            color: #ffffff;
        }

        h1, h2, h3, h4, h5, h6 {
            font-weight: 100;
            font-size: 2.4rem;
            margin: 0 0 5rem;
        }
    }

    @media all and (min-width: $screen-md-min) {
        margin-top: 6rem;
        margin-bottom: 8.5rem;

        .product-banner {
            @include flex(center, space-between);

            img {
                max-height: initial;
            }

            .text {
                width: 48%;
                padding: 30px 6.5%;

                p {
                    font-size: 2rem;
                    line-height: 3.2rem;
                }

                h1, h2, h3, h4, h5, h6 {
                    margin-bottom: 10rem;
                    font-size: 3.6rem;
                }
            }

            &:nth-child(odd) {
                .text {
                    order: 1;
                }

                .image {
                    order: 2;
                }
            }
        }
    }
}

.product.attribute.overview {
    margin: 40px 0;
    .value {
        font-size: 1.2rem;
        line-height: 20px;
    }

    @media all and (min-width: $screen-md-min) {
        .value {
            font-size: 1.4rem;
            line-height: 24px;
        }
    }
}
