// Author: Vasilis Neris
// Scss Base Library for Netsteps
//============================

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args) {
  -moz-transform: $args;
  -o-transform: $args;
  -ms-transform: $args;
  -webkit-transform: $args;
  transform: $args;
}
@mixin filter($args){
  -webkit-filter: $args;
  filter:$args
}

@mixin linear-gradient($args) {
  background: $args; /* Old browsers */
  background: -moz-linear-gradient(to right, $args); /* FF3.6+ */
  //background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(238,238,238,1)), color-stop(100%,rgba(238,238,238,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(to right, $args); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(to right, $args); /* Opera 11.10+ */
  background: -ms-linear-gradient(to right, $args); /* IE10+ */
  background: linear-gradient(to right, $args); /* W3C */
}

@mixin clear-both() {
  clear: both;
  content: "";
  display: table;
}

@mixin columns($num){
  -webkit-column-count: $num;
  -moz-column-count: $num;
  column-count: $num;
}

@mixin ion_icon_before($before) {
  content: $before;
  display: inline-block;
  font-family: "Ionicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin opacity($args) {
  opacity: $args;
  $opacity-ie: $args * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin border-radius($args) {
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: $args;
  /* Firefox 1-3.6 */
  -moz-border-radius: $args;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: $args;
  /* Prevent background color leak outs */
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

@mixin flex($align: 'initial', $justify: 'initial', $direction: 'initial') {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  @if $align != 'initial' {
    -webkit-box-align: $align;
    -moz-box-align: $align;
    -ms-flex-align: $align;
    -webkit-align-items: $align;
    align-items: $align;
  }
  @if $justify != 'initial' {
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    justify-content: $justify;
    -ms-flex-pack: $justify;
  }
  @if $direction != 'initial' {
    -webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
  }
}

@mixin align-items($align) {
  -webkit-box-align: $align;
  -moz-box-align: $align;
  -ms-flex-align: $align;
  -webkit-align-items: $align;
  align-items: $align;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin flex-order($order) {
  -webkit-box-ordinal-group: $order;
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  -webkit-order: $order;
  order: $order;
}

@mixin flex-wrap($args) {
  -webkit-flex-wrap: $args;
  -ms-flex-wrap: $args;
  flex-wrap: $args;
}

@mixin object-fit($args) {
  -o-object-fit: $args;
  object-fit: $args;
}

/* Functionality */
@mixin visibility($args) {
  @if $args == 'hidden' {
    visibility: hidden;
    @include opacity(0);
  }
  @if $args == 'visible' {
    visibility: visible;
    @include opacity(1);
  }
}

@mixin abs-centerX() {
  position: absolute;
  left: 50%;
  @include transform(translateX(-50%));
}

@mixin abs-centerY() {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin abs-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

@mixin events() {
  &:focus,
  &:active,
  &:hover {
    @content;
  }
}


@mixin ion_icon_before($before) {
  content: $before;
  display: inline-block;
  font-family: "Ionicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin fullwidth() {
  padding-left: calc((99.9vw - 100% + 15px) / 2);
  padding-right: calc((99.9vw - 100% + 15px) / 2);
  margin-left: calc((100% - 99.9vw + 15px) / 2);
  margin-right: calc((100% - 99.9vw + 15px) / 2);
}

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
  &::placeholder {
    @content
  }
}

@mixin background-gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}


@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }
  @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }
  @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
  @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}


@mixin scrollbars($size, $foreground-color, $radius: 0, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

}


@mixin colorHover($color, $trans) {
  @if $color != 'none' {
    @include events() {
      color: $color;
      @if $trans != 'none' {
        @include transition($trans);
      }
    }
  }
  @if $trans != 'none' {
    @include transition($trans);
  }
}

@mixin widthHeight($args){
  width: $args;
  height: $args;
}


@mixin animatedUnderline() {
  display: inline-block;
  position: relative;
  transition: .25s ease;
  &:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -5px;
    left: 0;
    background-color: color(black,base);
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s ease;
  }
  &:hover,&:focus{
    color: color(primetel, secondary);
    transition: .25s ease;
    &:before{
      visibility: visible;
      transform: scaleX(1);
    }
  }
}

@mixin boxShadow() {
    box-shadow: 0px 2px 30px rgba(35, 31, 32, 0.06);
}

@mixin productShadow(){
    box-shadow: 0px 2px 30px rgba(35,31,32,0.15);
}

@mixin hideElement(){
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    max-width: 1px;
    max-height: 1px;
}
