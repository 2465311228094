//
// Variables & Mixins
// If you need stand alone file take the below imports ONLY.
//============================
@import "components/_variables";
@import "components/_mixins";
@import "components/product-page/description";

.static_page_list_menu_header{
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 0;
    color: #191a1b;
    &:after{
        content: '';
        display: block;
        width: 100%;
        background-color: #191a1b;
        height: 2px;
        margin-top: 5px;
    }
    @media screen and (max-width: $screen-sm-max) {
        padding-bottom: 20px;
    }
}
.block-cms-link{
    position: relative;
    padding-left: 12px;
    &:before{
        display: block;
        content: '';
        position: absolute;
        height: 100%;
        width: 10px;
        left: -20px;
        background-color: color(text,secondary);
        opacity: 0;
        transition: all 0.2s;
    }
    &:hover{
        &:before{
            left: 0px;
            opacity: 1;
        }
    }
    &.active{
        &:before{
            left: 0px;
            opacity: 1;
        }
    }
    @media screen and (max-width: $screen-sm-max) {
        margin-bottom: 15px !important;
    }
}
